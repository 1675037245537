import { Skeleton } from "antd"
import React, { useState } from "react"

const ProfileFormSkeleton: React.FunctionComponent = () => {
  return (
    <>
      <div className="text-center">
        <Skeleton.Avatar active size={128} shape="circle" style={{ marginTop: '40px', marginBottom: '50px' }} />
        <br/>
        <Skeleton active></Skeleton>
        <Skeleton active></Skeleton>
        {/* <div className="profileBox">
          <Skeleton.Button style={{ width: 800 }} active size="default" />
        </div>
        <div className="profileBox">
          <Skeleton.Button style={{ width: 800 }} active size="default" />
        </div>
        <br/>
        <div className="profileBox">
          <Skeleton.Button style={{ width: 30 }} active size="default" />
        </div>
        <div className="profileBox">
          <Skeleton.Button style={{ width: 160 }} active size="default" />
        </div> */}
      </div>
    </>
  )
}

export default ProfileFormSkeleton
