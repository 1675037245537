import { paddingLeft } from "./string"

// interface FormatDate {
//   date: any
//   microtime: any
//   timestamp: number
//   y: number
//   m: number
//   d: number
//   yy: string
//   yyyy: string
//   mm: string
//   dd: string
//   hh: string
//   ii: string
//   ss: string
//   ms: string
//   ymd: string
//   daysInMonth: any
// }
export const makeDate = (date: any = null) => {
  const dateObj: any = {}
  let d: any = null
  if (date === null) {
    d = new Date()
  } else {
    d = str2date(date)
  }
  dateObj.date = d
  dateObj.microtime = d.getTime()
  dateObj.timestamp = Math.floor(d / 1000)

  dateObj.y = d.getFullYear()
  dateObj.m = d.getMonth() + 1
  dateObj.d = d.getDate()

  dateObj.yy = d.getFullYear().toString().substr(-2)

  dateObj.yyyy = paddingLeft(d.getFullYear(), '2000')
  dateObj.mm = paddingLeft(d.getMonth() + 1, '00')
  dateObj.dd = paddingLeft(d.getDate(), '00')

  dateObj.hh = paddingLeft(d.getHours(), '00')
  dateObj.ii = paddingLeft(d.getMinutes(), '00')
  dateObj.ss = paddingLeft(d.getSeconds(), '00')
  dateObj.ms = paddingLeft(d.getMilliseconds(), '000')

  dateObj.ymd = `${dateObj.yyyy}-${dateObj.mm}-${dateObj.dd}`
  dateObj.mNameLong = dateObj.date.toLocaleString('default', { month: 'long' })
  dateObj.mNameShort = dateObj.date.toLocaleString('default', { month: 'short' })

  // dateObj.daysInMonth = new Date(dateObj.yyyy, dateObj.m, 0).getDate()

  return dateObj
}

export const str2date = (str: string) => {
  return new Date(Date.parse(str))
}
