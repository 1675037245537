import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/charts';

interface IDXLineChartDataFormat {
  text: string
  value: number
}

interface IDxLineChart {
  loading: boolean
  data: IDXLineChartDataFormat[]
}

const DxLineChart: React.FunctionComponent<IDxLineChart> = ({ loading, data }) => {
  const config = {
    data,
    height: 200,
    xField: 'text',
    yField: 'value',
    point: {
      size: 5,
      shape: 'circle',
    },
  };
  return <Line {...config} loading={loading} />;

  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   asyncFetch();
  // }, []);
  // const asyncFetch = () => {
  //   fetch('https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json')
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       console.log('fetch data failed', error);
  //     });
  // };
  // var config = {
  //   data: data,
  //   padding: 'auto',
  //   xField: 'Date',
  //   yField: 'scales',
  //   xAxis: { tickCount: 5 },
  //   slider: {
  //     start: 0.1,
  //     end: 0.5,
  //   },
  // };
  // return <Line {...config} />
  // return <div>sss</div>
};

export default DxLineChart;