import { useState, useEffect } from "react";
import { Form, Input, Row, Col, InputNumber, Button, Divider, Avatar, Upload, Tooltip } from 'antd';
import { UserOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { apiUri } from "../../config/aplloClient";
import { ucfirst } from "../../utils/string";

interface IProfileFormSocialProp {
  editable: FormEditable
  socials: ProfileSocial[]
}

const socialTooptips = (platform: string) => {
  let text = ''
  switch (platform) {
    case 'facebook': text = 'Example: https://facebook.com/yoururl'
      break
    case 'line': text = 'Example: http://line.naver.jp/ti/p/yourcode\nGoto Setting -> My Profile -> My QR code -> Share'
      break
    case 'instagram': text = 'Example: https://instagram.com/yourid'
      break
    default: text = ''
      break
  }
  return text
}

const ProfileFormSocial: React.FunctionComponent<IProfileFormSocialProp> = ({ editable, socials }) => {
  const isEditable = !(editable === 'yes')
  
  return (
    <>
      {
        socials?.map((social, i) => {
          return (
            <Form.Item label={ucfirst(social.type)} key={`social-${i}`} tooltip={socialTooptips(social.type)}>
              <Input.Group compact>
                <Form.Item
                  name={['user', 'socials', i, 'type']}
                  noStyle
                  rules={[{ required: true, message: 'Social platyform is required' }]}
                >
                  <Input hidden disabled style={{ width: '25%' }} placeholder="Street" />
                </Form.Item>
                <Form.Item
                  name={['user', 'socials', i, 'endpoint']}
                  noStyle
                  rules={[{ required: false, message: 'Street is required' }]}
                >
                  <Input disabled={isEditable} placeholder="" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          )
        })
      }
    </>
  )
}

export default ProfileFormSocial
