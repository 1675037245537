export const ucfirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * @param {string} text is source text
 * @param {string} paddingValue is padding value do you want.
 *  for example text="2" paddingValue="00" result will be "02"
 */
 export const paddingLeft = (text: string, paddingValue: string) => {
  return String(paddingValue + text).slice(-paddingValue.length)
}
export const strPadLeft = (text: string, padString: string, targetLength: number) => {
  return text.padStart(targetLength, padString)
}
export const strPadRight = (text: string, padString: string, targetLength: number) => {
  return text.padEnd(targetLength, padString)
}
