import React, { useState } from "react";
import { Form, Input, Button, Divider, Avatar, Upload, Tooltip, Row, Col, Card, Typography, Badge } from 'antd';
import { UserOutlined, LoadingOutlined, CameraOutlined } from '@ant-design/icons';
import { apiUri } from "../../config/aplloClient";
import ProfileFormAddress from "./profileFormAddress";
import ProfileFormSocial from "./profileFormSocial";
import { alertMessage } from "../../utils/alert";
import './profileForm.less'

const { Title } = Typography
interface IProfileFormFormat {
  user: Profile
}

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const ProfileForm: React.FunctionComponent<IProfileFormProp> = ({ editable, initialValues, initialImageUrl, isImageUploading, isFormSaving, saveFormCallback, uploadImageCallback }) => {
  const isEditable = !(editable === 'yes')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.setFieldsValue({ user: initialValues });
  }, [initialValues])

  // React.useEffect(() => {
  //   setLoading(isFormSaving)
  // }, [isFormSaving])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 }
  }
  const validateMessages = {
    // required: '${label} is required!',
    types: {
      email: '${label} is not a valid email',
      number: '${label} is not a valid number',
      max: "'${name}' cannot be greater than ${max}",
    },
    string: {
      max: "'${name}' cannot be longer than ${max} characters"
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
      max: "'${name}' cannot be greater than ${max}"
    }
  };

  const avatarLoading = (
    <Avatar size={128} icon={<LoadingOutlined />} />
    // <div>
    //   {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
    //   <div style={{ marginTop: 8 }}>Upload</div>
    // </div>
  );

  const beforeUpload = (file: any) => {
    // setLoading(true)
    // console.log('FILE SELECTED', file)
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      console.error('You can only upload jpg, png or gif file.');
      alertMessage('Support file type jpg, png or gif only', 'warning')
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      alertMessage('Image must smaller than 2MB', 'warning')
      return false
    }
    const ableUploaded = isJpgOrPng && isLt2M;
    if (ableUploaded) {
      uploadImageCallback(file)
    } else {
      // setLoading(false)
      alertMessage('Unable to save your avatar file, please try again', 'error')
    }
    return false
  }

  // Submit form
  const onFinish = (values: IProfileFormFormat) => {
    saveFormCallback(values.user);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  }
  
  // style={{ fontSize: '20px', top: 'auto', bottom: 0, left: '40%', right: 'auto' }}
  return (
    <>
    <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages} layout="vertical" onFinishFailed={onFinishFailed}>
      <Row gutter={[30, 30]}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 16, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Personal Profile</Title>
            <Row>
              <Col className="text-center" style={{ marginBottom: '30px' }} xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 6, offset: 0 }}>
                <Upload
                  // name="avatar"
                  // listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  <Tooltip title="Click to upload">
                  <Badge size="default" status="default" count={<span className="editAvatarIcon text-center"><CameraOutlined /> Edit</span>}>
                  {
                    (isImageUploading) ? avatarLoading : 
                      (initialImageUrl) ? 
                        <Avatar
                          size={128}
                          src={initialImageUrl}
                        />
                      : <Avatar size={128} icon={<UserOutlined />} />
                  }
                  </Badge>
                  </Tooltip>
                </Upload>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 14, offset: 0 }} xl={{ span: 18, offset: 0 }}>
                <Form.Item name={['user', 'nickName']} label="" rules={[{ required: false, message: 'Nick name is required' }]} hidden>
                  <Input hidden disabled={isEditable} />
                </Form.Item>
                
                <Form.Item name={['user', 'firstName']} label="First Name" rules={[{ required: true, message: 'First name is required' }]}>
                  <Input disabled={isEditable} />
                </Form.Item>
                <Form.Item name={['user', 'lastName']} label="Last Name" rules={[{ required: true, message: 'Last name is required' }]}>
                  <Input disabled={isEditable} />
                </Form.Item>
                <Form.Item name={['user', 'position']} label="Position" rules={[{ required: true, message: 'Position is required' }]}>
                  <Input disabled={isEditable} />
                </Form.Item>
                <Form.Item name={['user', 'companyName']} label="Company" rules={[{ required: true, message: 'Company name is required' }]}>
                  <Input disabled={isEditable} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Phone</Title>
            <Form.Item name={['user', 'mobilePhone']} label="Mobile" rules={[{ required: true, message: 'Mobile Phone Number is required' }]}>
              <Input disabled={isEditable} placeholder="Number only e.g. 0861234567" />
            </Form.Item>
            <Form.Item name={['user', 'workPhone']} label="Office" rules={[{ required: false, message: 'Office Phone Number is not required' }]}>
              <Input disabled={isEditable} placeholder="Number only e.g. 0861234567 021234567" />
            </Form.Item>
            <Form.Item name={['user', 'homePhone']} label="Home" rules={[{ required: false, message: 'Home Phone Number is not required' }]}>
              <Input disabled={isEditable} placeholder="Number only e.g. 021234567" />
            </Form.Item>
          </Card>
        </Col>

        <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Email & Web</Title>
            <Form.Item name={['user', 'workEmail']} label="Work Email" rules={[{ type: 'email', required: true }]}>
              <Input disabled={isEditable} placeholder="name@email.com" />
            </Form.Item>
            <Form.Item name={['user', 'homeEmail']} label="Personal Email" rules={[{ type: 'email' }]}>
              <Input disabled={isEditable} placeholder="email@company.com" />
            </Form.Item>

            <Form.Item name={['user', 'workUrl']} label="Office Website" rules={[{ type: 'url', required: true, message: 'Work url is required' }]}>
              <Input disabled={isEditable} placeholder="https://yourweb.com" />
            </Form.Item>
            <Form.Item name={['user', 'homepageUrl']} label="Your Website" rules={[{ type: 'url', required: false }]}>
              <Input disabled={isEditable} placeholder="https://yourweb.com" />
            </Form.Item>
            <Form.Item name={['user', 'note']} label="Note">
              <Input.TextArea disabled={isEditable} autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </Card>
        </Col>
        
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Address</Title>
            <ProfileFormAddress
              editable={editable}
              addresses={initialValues?.address || []}
            />
          </Card>
        </Col>

        {/* <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Timeline mode="left">
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">Just now, Access from Chrome on Mac OS (desktop), </Tooltip></Timeline.Item>
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">A minute ago, Access from Chrome on Mac OS (desktop), Just now</Tooltip></Timeline.Item>
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">Access from Chrome on Mac OS (desktop), Just now</Tooltip></Timeline.Item>
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">Access from Chrome on Mac OS (desktop), Just now</Tooltip></Timeline.Item>
            </Timeline>
          </Card>
        </Col> */}

        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Socials</Title>
              <ProfileFormSocial
                editable={editable}
                socials={initialValues?.socials || []}
              />
          </Card>
        </Col>
        <Col xs={{ span: 24, offset: 0 }}>
          {
            (!isEditable) ?
              <div className="text-center">
                <Button type="primary" htmlType="submit" loading={isFormSaving}>
                  Save
                </Button>
              </div>
            : ''
          }
        </Col>
      </Row>
    </Form>
    </>
  )
}

export default ProfileForm
