import { message, Button } from 'antd';

type MessageType = 'success' | 'error' | 'info' | 'warning' | 'loading'

/**
 * 
 * @param msg Alert Message
 * @param type Message type
 * @param duration In second format
 */
export const alertMessage = (msg: string, type: MessageType = 'info', duration: number = 3): void => {
  message.destroy()
  const config = { content: msg, duration }
  message[type](msg, duration)
  // if (type === 'success') {
  //   message.success({ content: msg, duration });
  // } else if (type === 'error') {
  //   message.error({ content: msg, duration });
  // } else if (type === 'info') {
  //   message.info({ content: msg, duration });
  // } else if (type === 'warning') {
  //   message.warning({ content: msg, duration });
  // } else if (type === 'loading') {
  //   message.loading({ content: msg, duration });
  // }
}