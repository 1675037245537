export const getGraphQlErrorCode = (graphResonse: any, index: number = 0) => {
  let code = null
  if (typeof graphResonse.graphQLErrors[index] !== 'undefined') {
    console.log(graphResonse.graphQLErrors[index], graphResonse.graphQLErrors[index].extensions)
    if (typeof graphResonse.graphQLErrors[index].extensions.code !== 'undefined') {
      code = graphResonse.graphQLErrors[index].extensions.code
    }
  }
  return code
}
