import { Card, Col, Row, Statistic, Timeline, Typography, Tooltip } from 'antd'
import { DesktopOutlined } from '@ant-design/icons'
import ProfileFormSkeleton from '../../components/profileForm/profileFormSkeleton'
import DxLineChart from '../../components/graph/line'
import { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GRAPH_QUERY_SELF_STATISTIC, GRAPH_QUERY_SELF_STATISTIC_TRANSACTION } from '../../graphql/statistic'
import StatisticAccessTransacion from './tableTransaction'
import { makeDate } from '../../utils/date'
import ErrorLoadData from '../../components/errors/loadDataError'

const { Title, Text } = Typography

interface IPlatformObject {
  iOS: IPlatformObjectSecond
}

interface IPlatformObjectSecond {
  mobile: string
  tablet: string
}

const StatisticPage: React.FunctionComponent = () => {

  // const [isLoading, setLoading] = useState<boolean>(true)
  const [totalAccess, setTotalAccess] = useState<ITotalAccess>()
  const [dayAccess, setDayAccess] = useState<IAccessEachDay[]>([])
  const [transactionAccess, setTransactionAccess] = useState<IAccessTransaction[]>([])

  const { loading: loading1, error: error1, data: data1 } = useQuery(GRAPH_QUERY_SELF_STATISTIC, { variables: {} })
  const { loading: loading2, error: error2, data: data2 } = useQuery(GRAPH_QUERY_SELF_STATISTIC_TRANSACTION, { skip: (loading1 || !!error1), variables: {} })


  const devicePlatformDetect = (os: string, platform: string) => {
    let newPlatform = platform
    if (os === 'iOS') {
      switch (platform) {
        case 'mobile': newPlatform = 'iPhone'
          break;
        case 'tablet': newPlatform = 'iPad'
          break;
      }
    }
    // const platformList: IPlatformObject = {
    //   iOS: {
    //     mobile: 'iPhone',
    //     tablet: 'iPad'
    //   }
    // }
    // if (typeof platformList[os] !== 'undefined') {

    // }
    return newPlatform
  }

  useEffect(() => {
    if (!error1 && !loading1) {
      if (data1.selfStatistic !== null) {
        const stat = data1.selfStatistic
        setTotalAccess(stat.accessSummary)

        setDayAccess(stat.accessDays)
      }
    }

    if (!loading2) {
      if (data2) {
        const stat = data2.selfStatisticTransaction
        const newData = stat.data.map((v: any, i: any) => {
          const fd = makeDate(v.date)
          const platform = devicePlatformDetect(v.os, v.platform)
          return {
            ...v,
            ...{
              key: i + 1,
              dateOnly: `${fd.d} ${fd.mNameLong} ${fd.yyyy}`,
              timeOnly: `${fd.hh}:${fd.ii}`,
              dateTime: `${fd.d} ${fd.mNameLong} ${fd.yyyy} - ${fd.hh}:${fd.ii}`,
              accessFrom: `${v.os} ${v.osVersion}${v.platform ? ` (${platform})` : ''}`,
              platform
            }
          }
        })
        setTransactionAccess(newData)
      }
    }
  }, [data1, data2])

  if (error1) return <ErrorLoadData />
  return (
    <div>
      <Row gutter={[30, 30]}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Statistic title="Total Access" value={totalAccess?.totalAccess} loading={loading1} valueStyle={{ fontSize: '30px' }} />
          </Card>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Statistic title="Yesterday" value={totalAccess?.yesterdayAccess} loading={loading1} valueStyle={{ fontSize: '30px' }} />
          </Card>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Statistic title="Today" value={totalAccess?.todayAccess} valueStyle={{ color: '#3f8600', fontSize: '30px' }} loading={loading1} />
          </Card>
        </Col>
        
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Access in each day</Title>
            <div style={{ marginTop: '40px' }}><DxLineChart loading={loading1} data={dayAccess} /></div>
          </Card>
        </Col>

        {/* <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Timeline mode="left">
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">Just now, Access from Chrome on Mac OS (desktop), </Tooltip></Timeline.Item>
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">A minute ago, Access from Chrome on Mac OS (desktop), Just now</Tooltip></Timeline.Item>
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">Access from Chrome on Mac OS (desktop), Just now</Tooltip></Timeline.Item>
              <Timeline.Item dot={<DesktopOutlined className="timeline-clock-icon" />}><Tooltip title="2015-09-01 09:12:11">Access from Chrome on Mac OS (desktop), Just now</Tooltip></Timeline.Item>
            </Timeline>
          </Card>
        </Col> */}

        <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
          <Card title="" bordered={false} className="menu-card active">
            <Title level={4} className="digix-card-title">Last 50 Accesses</Title>
            {error2 ? 'Load Data Error please try again' : <StatisticAccessTransacion loading={loading2} data={transactionAccess} />}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StatisticPage
