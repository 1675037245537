import logo from './static/logo/logo.png'
import './App.less'
import { Layout } from 'antd'
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link as RouteLink
} from 'react-router-dom'
import HomePage from './components/home/home'
import LoginPage from './containers/login/login'
import useToken from './utils/token'
import { Typography } from "antd"
import ContentLayout from './containers/contentLayout/contentLayout' // Have white box
import BlankLayout from './containers/blankLayout/blankLayout' // Doesn't have white box
import ShortMenu from './components/menu/shortMenu'
import Error404 from './components/errors/404'

const { Text } = Typography

const { Header, Content, Footer } = Layout

function App() {
  const { token, setToken } = useToken()

  if (!token) {
    return <LoginPage setToken={setToken} />
  }

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div id="logo">
          <img id="logo-img" src={logo} />
        </div>
        {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
          <Menu.Item key="menu-dashboard"><RouteLink to ="/">Home</RouteLink></Menu.Item>
          <Menu.Item key="menu-info"><RouteLink to ="/profile">Information</RouteLink></Menu.Item>
        </Menu> */}
        <div className="header-right"><ShortMenu /></div>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
        <div>
          {/* <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item><RouteLink to ="/home"><HomeOutlined style={{ marginRight: '5px' }} />Home</RouteLink></Breadcrumb.Item>
          </Breadcrumb> */}
          <Switch>
            <Redirect exact from="/" to="home" />
            <Route path="/home" component={HomePage} />
            <Route path="/profile/" component={BlankLayout} />
            <Route path="/statistic/" component={BlankLayout} />
            <Route path="/share/" component={BlankLayout} />
            <Route path="*" component={Error404} />
          </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}><Text type="secondary">Copyright ©2021 Created by Digitive Works Team</Text></Footer>
    </Layout>
  )
}

export default App
