import gql from 'graphql-tag';

export const GRAPH_QUERY_SELF_PROFILE = gql`
  query {
    selfProfile {
      image
      firstName
      lastName
      nickName
      birthday
      position
      companyName
      mobilePhone
      homePhone
      workPhone
      homeEmail
      workEmail
      homepageUrl
      workUrl
      note
      vcf
      address {
        type
        street
        street2
        district
        province
        postalCode
        country
      }
      socials {
        type
        endpoint
      }
    }
  }
`;

export const GRAPH_QUERY_SELF_PROFILE_PUBLIC_URL = gql`
  query {
    selfProfile {
      profileUrl
    }
  }
`;

export const GRAPH_MUTATION_EDIT_SELF_PROFILE = gql`
  mutation updateSelfProfile($user: ProfileInput!){
    updateSelfProfile(
      user: $user
    )
  }
`

export const GRAPH_MUTATION_EDIT_SELF_PROFILE_AVATAR = gql`
  mutation ($file: Upload!){
    changeSelfAvatar(
      file: $file
    ) {
      url
    }
  }
`