import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import LoginForm from '../../components/loginForm/loginForm'
import { GRAPH_MUTATION_LOGIN } from '../../graphql/auth'
import { alertMessage } from '../../utils/alert'
import { getGraphQlErrorCode } from '../../utils/graphRequest'
import '../../css/login.less'

import { Card, Col, Row, Typography } from 'antd'
const { Title, Text } = Typography

interface ILoginPageInput {
  setToken: any
}

const LoginPage: React.FunctionComponent<ILoginPageInput> = ({ setToken }) => {
  const [loginRequest] = useMutation<ILoginResponse>(GRAPH_MUTATION_LOGIN)

  const [isLoading, setLoading] = useState<boolean>(false)

  const submitLogin = async (credential: ILoginCredential) => {
    setLoading(true)
    loginRequest({
      variables: {
        user: credential.username,
        passwd: credential.password
      }
    }).then(({ data }) => {
      if (!data?.login.error) {
        const loginData = data?.login
        if (typeof loginData?.token === 'string' && typeof loginData?.refreshToken === 'string') {
          setToken(loginData.token, loginData.refreshToken)
          alertMessage('Authentication Succeeded!', 'success')
        } else {
          setLoading(false)
          alertMessage('Authenticated but something went wrong.', 'warning')
        }
      } else {
        setLoading(false)
        alertMessage('Authenticated but something went wrong.', 'warning')
      }
    }).catch((e: any) => {
      setLoading(false)
      const errCode = getGraphQlErrorCode(e)
      if (errCode === 'LOGIN_INVALID') {
        alertMessage('Username or Password is invalid.', 'error')
      } else {
        alertMessage('Internal Server error', 'error')
      }
    })
  }

  return (
    <div>
      <div>
        <div className="authen-wrapper">
          <Row gutter={0} style={{ textAlign: 'center' }}>
            <Col xs={{ span: 22, offset: 1 }}>
              <Card title="" bordered={false}>
                <div style={{ marginTop: '40px' }}><img src={`/logo192.png`} style={{ width: '120px' }} /></div>
              </Card>
            </Col>
            <Col xs={{ span: 22, offset: 1 }}>
              <Card title="" bordered={false}>
                <Title level={4} style={{ marginBottom: '25px' }}>Login</Title>
                <LoginForm
                  submitLoginCallback={submitLogin}
                  isLoading={isLoading}
                />
              </Card>
            </Col>
          </Row>
        </div>
        <div className="text-center">
          <Title level={5}>DigixCards</Title>
          <div><Text type="secondary">Created by Digitive Works Team</Text></div>
          <div><Text type="secondary">If you have any question please contact <a href="mailto:connect@digitiveworks.com">connect@digitiveworks.com</a></Text></div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage