import { useState, useEffect } from 'react'
import * as tokenHandle from './auth'

export default function useToken() {
  const getToken = () => {
    return localStorage.getItem('token') || null
  }

  const [token, setToken] = useState(getToken())

  const saveToken = (token: string, refreshToken: string) => {
    saveTokenOnly(token)
    saveRefreshToken(refreshToken)
    setToken(token)
    return true
  }

  const saveTokenOnly = (token: string) => {
    const set = tokenHandle.setToken(token)
    return true
  }

  const saveRefreshToken = (refreshToken: string) => {
    const setRfToken = tokenHandle.setRefreshToken(refreshToken)
    return true
  }

  return {
    token,
    setToken: saveToken,
    setTokenOnly: saveTokenOnly,
    getToken
  }
}
