import { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Divider, Avatar, Upload, Tooltip } from 'antd';
import { UserOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { apiUri } from "../../config/aplloClient";
import { ucfirst } from "../../utils/string";

interface IProfileFormAddressProp {
  editable: FormEditable
  addresses: ProfileAddress[]
}

const ProfileFormAddress: React.FunctionComponent<IProfileFormAddressProp> = ({ editable, addresses }) => {
  const isEditable = !(editable === 'yes')
  // const [form] = Form.useForm();
  const [addrs, setAddresses] = useState<ProfileAddress[] | []>([])
  useEffect(() => {
    setAddresses(addresses)
  }, [JSON.stringify(addresses)])
  
  return (
    <>
      {
        addresses?.map((addr, i) => {
          return (
            <Form.Item label={ucfirst(addr.type)} key={`address-${i}`}>
              <Input.Group compact>
                <Form.Item
                  name={['user', 'address', i, 'street']}
                  noStyle
                  rules={[{ required: true, message: 'Street is required' }]}
                >
                  <Input disabled={isEditable} placeholder="Street" />
                </Form.Item>
                <Form.Item
                  name={['user', 'address', i, 'street2']}
                  noStyle
                  rules={[{ required: false, message: 'Street2 is not required' }]}
                >
                  <Input disabled={isEditable} placeholder="Input street2" />
                </Form.Item>
                <Form.Item
                  name={['user', 'address', i, 'district']}
                  noStyle
                  rules={[{ required: true, message: 'District is required' }]}
                >
                  <Input disabled={isEditable} style={{ width: '50%' }} placeholder="Input district" />
                </Form.Item>
                <Form.Item
                  name={['user', 'address', i, 'province']}
                  noStyle
                  rules={[{ required: true, message: 'Province is required' }]}
                >
                  <Input disabled={isEditable} style={{ width: '50%' }} placeholder="Input province" />
                </Form.Item>
                <Form.Item
                  name={['user', 'address', i, 'postalCode']}
                  noStyle
                  rules={[{ required: true, message: 'Postal code is required' }]}
                >
                  <Input disabled={isEditable} style={{ width: '50%' }} placeholder="Input postal code" />
                </Form.Item>
                <Form.Item
                  name={['user', 'address', i, 'country']}
                  noStyle
                  rules={[{ required: true, message: 'Country is required' }]}
                >
                  <Input disabled={isEditable} style={{ width: '50%' }} placeholder="Input country" />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          )
        })
      }
    </>
  )
}

export default ProfileFormAddress
