/**
 * If you want to use nesting route
 * See here: https://reactrouter.com/web/example/nesting
 */
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import { PageHeader } from 'antd'
import EditForm from '../profile/editSelf'
import { dxUrlBack } from "../../utils/dxRedirect";
import StatisticPage from "../statistic/statistic";
import SharingForm from "../../components/sharingForm/sharingForm";

const BlankLayout = () => {
  const { path, url } = useRouteMatch()
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => dxUrlBack() }
        title="Back"
        subTitle=""
      />
      <div className="" style={{ minHeight: 380 }}>
        <Switch>
          <Route path="/profile/" component={EditForm} />
          <Route path="/statistic/" component={StatisticPage} />
          <Route path="/share/" component={SharingForm} />
        </Switch>
      </div>
    </>
  )
}

export default BlankLayout
