import { LogoutOutlined } from '@ant-design/icons'
import { destryoAllCredential } from '../../utils/auth'
import { dxRouteUrl } from '../../utils/dxRedirect'
import { useMutation } from '@apollo/client'
import { GRAPH_MUTATION_LOGOUT } from '../../graphql/auth'
import { alertMessage } from '../../utils/alert'
interface ILogoutForm {
  
}

const LogoutMenuList: React.FunctionComponent<ILogoutForm> = () => {
  const [logoutRequest] = useMutation<ILoginResponse>(GRAPH_MUTATION_LOGOUT)

  const activeLogout = () => {
    logoutRequest().then(
      ({ data }) => {
        alertMessage('Logged-out', 'success') 
        destryoAllCredential()
        setTimeout(() => {
          dxRouteUrl('/')
        }, 100)
    }).catch((e: any) => {
      console.error('Logout error: ', JSON.stringify(e))
    })
   
  }

  return (
    <a type="primary" onClick={activeLogout}>
      <span className="shortMenuIcon"><LogoutOutlined className="shortMenuIcon" /></span>Logout
    </a>
  )
}

export default LogoutMenuList