import { Card, Col, Result, Row, Badge } from 'antd'
import {
  Link
} from 'react-router-dom'
import { LineChartOutlined, UserOutlined, AppstoreAddOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { GRAPH_QUERY_HEAL } from '../../graphql/auth';
import { useEffect } from 'react';
import { destroyAndRedirect } from '../../config/aplloClient';

const HomePage: React.FunctionComponent = () => {
  // Pulling check session available
  let [pullHeal, { loading, error, data }] = useLazyQuery(GRAPH_QUERY_HEAL)
  
  useEffect(() => {
    pullHeal()
    if (!loading) {
      if (!error && data) {
        // If heal is not ok then redirect to login page
        const heal = data?.heal.ok
        if (!heal) {
          destroyAndRedirect()
        }
      }
    }
  }, [data])
  return (
    <>
      <div id="home-menu" className="site-card-wrapper">
        <Row gutter={[30, 30]} style={{ textAlign: 'center' }}>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
            <Link to="/statistic">
              <Card title="" bordered={false} className="menu-card">
                <Result
                  icon={<LineChartOutlined style={{ color: 'rgb(112 81 231)' }} />}
                  title="Statistic"
                  subTitle="Who visit to your profile? Can see on this menu"
                  extra={[]}
                />
              </Card>
            </Link>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
            <Link to="/profile">
              <Card title="" bordered={false} className="menu-card">
                <Result
                  icon={<UserOutlined style={{ color: '#3171f5' }} />}
                  title="Your Profile"
                  subTitle="Do you want to change your profile or avatar? please enter this menu"
                  extra={[]}
                />
              </Card>
            </Link>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
            <Link to="/home">
              <Badge.Ribbon text="Coming Soon" color="rgb(250 151 28)">
                <Card title="" bordered={false} className="menu-card">
                  <Result
                    icon={<AppstoreAddOutlined style={{ color: '#fe22c2' }} />}
                    title="Theme"
                    subTitle="Choose your favorite theme here :)"
                    extra={[]}
                  />
                </Card>
              </Badge.Ribbon>
            </Link>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 12, offset: 0 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
            <Link to="/share">
              {/* <Badge.Ribbon text="Coming Soon" color="rgb(250 151 28)"> */}
                <Card title="" bordered={false} className="menu-card">
                  <Result
                    icon={<ShareAltOutlined style={{ color: 'rgb(34 62 147)' }} />}
                    title="Share"
                    subTitle="Share your profile to everyone"
                    extra={[]}
                  />
                </Card>
              {/* </Badge.Ribbon> */}
            </Link>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default HomePage