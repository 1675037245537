import { Button, Result, Typography } from 'antd'
// import { CloseCircleOutlined } from '@ant-design/icons';

// const { Paragraph, Text } = Typography

const ErrorLoadData: React.FunctionComponent = () => {
  return (
    <Result
      status="warning"
      title="Unable to load data right now"
      subTitle="Please try again later."
      extra={[
      ]}
    >
  </Result>
  )
}

export default ErrorLoadData