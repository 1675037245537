// More detail: https://github.com/jaydenseric/apollo-upload-examples
//         and  https://www.apollographql.com/docs/react/api/link/introduction/
import {
  ApolloClient,
  InMemoryCache,
  from,
  ApolloLink,
  Observable
} from '@apollo/client'
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from 'apollo-upload-client'
import { GRAPH_MUTATION_REFRESH_TOKEN_STRING } from '../graphql/auth'
import { alertMessage } from '../utils/alert'
import { destryoAllCredential, getRefreshToken, getToken, setToken } from '../utils/auth'
import { dxRouteUrl } from '../utils/dxRedirect'
import { inArray } from '../utils/object'
import promiseToObservable from './promiseToObservable'

export const apiUri: string = (process.env.REACT_APP_API_URI as string)

export const destroyAndRedirect = () => {
  destryoAllCredential()
  dxRouteUrl('/')
}

const fetchNewToken = () => {
  return new Promise((resolve, reject) => {
    fetch(apiUri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: GRAPH_MUTATION_REFRESH_TOKEN_STRING,
        variables: {
          token: getToken(),
          refreshToken: getRefreshToken()
        }
      })
    })
    .then(res => res.json())
    .then((json: any) => {
      // const { data: { refreshToken: newToken } } = json
      const response = json
      if (typeof response.data !== 'undefined' && response.data !== null) {
        const newToken = response.data.refreshToken
        setToken(newToken.token)
        resolve(newToken)
      } else {
        destroyAndRedirect()
        reject(null)
      }
    }).catch(err => {
      destroyAndRedirect()
      reject(err)
    })
  })
}

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken()
  operation.setContext(() => ({
    headers: {
      authorization: `Bearer ${token}`
    }
  }))
  return forward(operation)
})

const uploadLink = createUploadLink({
  uri: apiUri
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    const errorList: Array<string> = ['JsonWebTokenError', 'REFRESH_TOKEN_FAILED', 'UNAUTHENTICATED']
    const errorCode = graphQLErrors[0].extensions?.code
    const oldHeaders = operation.getContext().headers

    if (errorCode === 'TokenExpiredError') {
      // Refresh token
      return new Observable((observer) => {
        // Call refresh token
        fetchNewToken().then((t: any) => {
          operation.setContext({
            headers: {
              ...oldHeaders,
              Authorization: `Bearer ${t.token}`
            }
          })
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          }
          return forward(operation).subscribe(subscriber)
          // return promiseToObservable(fetchNewToken()).flatMap(() => forward(operation));
        })
      })
    } else if (inArray(errorList, errorCode)) {
      // Destroy token and redircet to main page
      console.log('REFRESH_TOKEN_ERROR: ', errorCode)
      destroyAndRedirect()
    } else {
      // Something went wrong / INTERNAL_SERVER_ERROR
      // alertMessage('Sorry :( Something went wrong, please try again.', 'error')
      console.log(`Error code "${errorCode}" Something went wrong`)
    }
  }

  if (networkError) {
    alertMessage('[Network unstable] Unable to connect to server.', 'warning', 5)
    console.log(`[Network error]: ${networkError}`)
  }
})

const apolloClient = (cache = {}) =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache().restore(cache),
    link: from([errorLink, authLink, uploadLink])
  })

// apolloClient.defaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'ignore'
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all'
//   }
// }


export default apolloClient
