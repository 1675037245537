import gql from 'graphql-tag';

export const GRAPH_QUERY_HEAL = gql`
  query {
    heal {
      ok
    }
  }
`;

export const GRAPH_MUTATION_LOGIN = gql`
  mutation login($user: String!, $passwd: String!){
    login(user: $user, passwd: $passwd){
      state
      token
      refreshToken
      date
      error
    }
  }
`;

export const GRAPH_MUTATION_LOGOUT = gql`
  mutation {
    logout
  }
`;

export const GRAPH_MUTATION_CHANGE_SELF_PASSWORD = gql`
  mutation login($currentPassword: String!, $newPassword: String!, $confirmPassword: String!){
    changeSelfPassword(currentPassword: $currentPassword, newPassword: $newPassword, confirmPassword: $confirmPassword)
  }
`;


/**
 * GRAPH QUERY STRING FOR REFRESH TOKEN ONLY
 */
export const GRAPH_MUTATION_REFRESH_TOKEN_STRING = `
  mutation($token: String!, $refreshToken: String!) {
    refreshToken(token: $token, refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`