import { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ProfileForm from '../../components/profileForm/profileForm'
import { GRAPH_MUTATION_EDIT_SELF_PROFILE, GRAPH_MUTATION_EDIT_SELF_PROFILE_AVATAR, GRAPH_QUERY_SELF_PROFILE } from '../../graphql/profile'
import { alertMessage } from '../../utils/alert'
import ProfileFormSkeleton from '../../components/profileForm/profileFormSkeleton'
import ErrorLoadData from '../../components/errors/loadDataError'

interface SelfProfile {
  selfProfile: Profile
}

interface SelfProfileEdit {
  user: Profile
}

interface SelfProfileEditAvatar {
  file: IFileUpload
}

const EditProfile = () => {
  let { loading, error, data } = useQuery<SelfProfile>(
    GRAPH_QUERY_SELF_PROFILE,
    {
      variables: {},
    }
  )

  const [profile, setProfileValue] = useState<Profile>()
  const [imageUrl, changeImageUrl] = useState<string>('')
  useEffect(() => {
    // profile.selfProfile.image = 
    setProfileValue(data?.selfProfile)
    changeImageUrl(data?.selfProfile.image || '')
  }, [data])

  // Form saving
  const [formSaving, setFormSaving] = useState<boolean>(false)
  // useEffect(() => {
  //   setFormSaving(formSaving)
  // }, [formSaving])
  // Image uploading
  const [imageUploading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setLoading(imageUploading)
  }, [imageUploading])

  const [saveProfileSelf] = useMutation<SelfProfileEdit>(GRAPH_MUTATION_EDIT_SELF_PROFILE)
  const [saveProfileSelfAvatar, graphUploadAvatar] = useMutation<SelfProfileEditAvatar>(GRAPH_MUTATION_EDIT_SELF_PROFILE_AVATAR)

  const saveFormSelf = (params: Profile) => {
    setFormSaving(true)
    saveProfileSelf({
      variables: { user: params }
    }).then((result: any) => {
      setFormSaving(false)
      if (result.data.updateSelfProfile) {
        alertMessage('Profile saved!', 'success')
      } else {
        alertMessage('Save profile failed, please try again.', 'error')
      }
    }).catch((e: any) => {
      console.error(e)
    })
  }    

  const uploadImageSelf = (file: any) => {
    setLoading(true)
    saveProfileSelfAvatar({
      variables: { file }
    }).then((result: any) => {
      setLoading(false)
      const image = result.data.changeSelfAvatar.url || null
      if (image) {
        changeImageUrl(image)
        alertMessage('Your avatar has been changed!', 'success')
      } else {
        alertMessage('Unable to change your avatar, please try again.', 'error')
      }
      return true
    }).catch((e: any) => {
      console.error(e)
    })
  }

  const isEditable = 'yes'
  if (loading) return (<div><ProfileFormSkeleton /></div>)
  if (error) return <ErrorLoadData />
  if (!data) return <ErrorLoadData />
  return (
    <div style={{ marginTop: '30px' }}>
      <ProfileForm
        editable={isEditable}
        isImageUploading={imageUploading}
        isFormSaving={formSaving}
        initialImageUrl={imageUrl}
        initialValues={profile}
        saveFormCallback={saveFormSelf}
        uploadImageCallback={uploadImageSelf}
        // isSubmiting={loading || loadingUpdate}
        // handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default EditProfile
