const crendentialKeys = {
  token: 'token',
  refreshToken: 'refreshToken'
}

export const setToken = (token: string) => {
  const setToken = localStorage.setItem(crendentialKeys.token, token)
  return true
}

export const setRefreshToken = (refreshToken: string) => {
  const setRfToken = localStorage.setItem(crendentialKeys.refreshToken, refreshToken)
  return true
}

export const getToken = () => {
  return localStorage.getItem(crendentialKeys.token) || null
}

export const getRefreshToken = () => {
  return localStorage.getItem(crendentialKeys.refreshToken) || null
}


export const destryoAllCredential = () => {
  localStorage.clear()
  return true
}