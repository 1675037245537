import gql from 'graphql-tag';

export const GRAPH_QUERY_SELF_STATISTIC = gql`
  query {
    selfStatistic {
      accessSummary {
        totalAccess
        todayAccess
        yesterdayAccess
      }
      accessDays {
        text
        value
      }
    }
  }
`;

export const GRAPH_QUERY_SELF_STATISTIC_TRANSACTION = gql`
  query {
    selfStatisticTransaction {
      total
      filtered
      data {
        platform
        browser
        os
        osVersion
        date
      }
    }
  }
`;
