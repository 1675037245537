import { Menu, Drawer, Typography, Divider } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import LogoutMenuList from '../logoutForm/logoutMenuList'
// import ChangePasswordMenuList from './changePassword'
import { useState } from 'react'
import { KeyOutlined } from '@ant-design/icons'
import ChangePasswordForm from '../changePasswordForm/changePasswordForm'
import './shortMenu.less'

const { Paragraph, Text } = Typography

interface ICurrentMenuItem {
  title: string
  component: any
}

const ShortMenu: React.FunctionComponent = () => {
  const [currentMenu, setCurrentMenu] = useState<ICurrentMenuItem>()
  // const [currentComponent, setCurrentComponent] = useState<any>()
  const [visibleLv1, setVisibleLv1] = useState(false)
  const [visibleLv2, setVisibleLv2] = useState(false)

  const showDrawerLv1 = () => { setVisibleLv1(true) }
  const onLv1Close = () => { setVisibleLv1(false) }
  const showDrawerLv2 = () => { setVisibleLv2(true) }
  const onLv2Close = () => { setVisibleLv2(false) }

  const menuList = [
    {
      title: 'Change Password',
      icon: (<KeyOutlined />),
      component: (<ChangePasswordForm onLv1Close={onLv1Close} onLv2Close={onLv2Close} />)
    }
  ]

  const changeCurrentMenu = (menuNumber: number) => {
    onLv2Close()
    if (typeof menuList[menuNumber] !== 'undefined') {
      if (menuList[menuNumber].title !== 'Logout') {
        setCurrentMenu(menuList[menuNumber])
        showDrawerLv2()
      }
    }
  }

  const menus = (
    <>
      <div style={{ marginBottom: '25px' }}><Text type="secondary">Setting</Text></div>{
        menuList.map((v, i) => {
          return (<><Paragraph key={i}><a type="primary" onClick={() => changeCurrentMenu(i)}><span className="shortMenuIcon">{v.icon}</span>{v.title}</a></Paragraph></>)
        })
      }<Divider style={{ marginTop: '10px', marginBottom: '10px' }} /><Paragraph key={999}><LogoutMenuList /></Paragraph>
    </>
    // <Menu id="menuList">
    //   <Menu.Item key="0" disabled>Setting</Menu.Item>
    //   <Menu.Divider />
    //   <Menu.Item key="1" onClick={() => changeCurrentMenu(0)}><ChangePasswordMenuList /></Menu.Item>
    //   <Menu.Item key="2" onClick={() => changeCurrentMenu(1)}><LogoutMenuList /></Menu.Item>
    // </Menu>
  )
  
  return (
    <>
      {/* <Dropdown overlay={menus} trigger={['click']}> */}
        {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> */}
      {/* </Dropdown> */}
      <a className="ant-dropdown-link" onClick={showDrawerLv1}>
        <MenuOutlined style={{ fontSize: '20px' }} />
      </a>
      <Drawer
        title=""
        closable={false}
        onClose={onLv1Close}
        visible={visibleLv1}
      >
        {/* <a type="primary" onClick={() => changeCurrentMenu(1)}>
          Two-level drawer
        </a> */}
        {menus}
        <Drawer
          title={currentMenu?.title}
          width="100%"
          onClose={onLv2Close}
          visible={visibleLv2}
        >
          {currentMenu?.component}
        </Drawer>
      </Drawer>
      
    </>
  )
}

export default ShortMenu