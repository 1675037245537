import { useMutation } from "@apollo/client"
import { Button, Card, Col, Divider, Form, Input, Row } from "antd";
import { useState } from "react";
import { GRAPH_MUTATION_CHANGE_SELF_PASSWORD } from "../../graphql/auth";
import { alertMessage } from "../../utils/alert";

interface IChangePasswordInput {
  onLv1Close?: any
  onLv2Close?: any
}

interface IChangePasswordForm {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePasswordForm: React.FunctionComponent<IChangePasswordInput> = ({ onLv1Close = null, onLv2Close = null }) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [changePasswordRequest] = useMutation<ILoginResponse>(GRAPH_MUTATION_CHANGE_SELF_PASSWORD)

  // const activeLogout = () => {
  //   changePasswordRequest().then(
  //     ({ data }) => {
        
  //   }).catch((e: any) => {
  //     console.error('Chnage psasword error: ', JSON.stringify(e))
  //   })
  // }

  const onFinish = (values: IChangePasswordForm) => {
    setLoading(true)
    changePasswordRequest({
      variables: values
    }).then((result: any) => {
      setLoading(false)
      const changed = result.data.changeSelfPassword || null
      if (changed) {
        alertMessage('Your password has been changed!', 'success', 6)
        form.resetFields()
        if (typeof onLv1Close === 'function') {
          onLv1Close()
        }
        if (typeof onLv2Close === 'function') {
          onLv2Close()
        }
      } else {
        alertMessage('Unable to change your password, please try again.', 'error')
      }
      return true
    }).catch((e: any) => {
      setLoading(false)
      alertMessage(e.message, 'warning')
    })
    return false
  }

  return (
    <div>
      <Row gutter={0} style={{ textAlign: 'center' }}>
        <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} xxl={{ span: 6, offset: 9 }}>
          <Card title="" bordered={false} className="menu-card active">
            {/* Change Your Password */}
            <Form
              // {...formItemLayout}
              form={form}
              name="changePassword"
              onFinish={onFinish}
              layout="vertical"
              // initialValues={{
              //   residence: ['zhejiang', 'hangzhou', 'xihu'],
              //   prefix: '86',
              // }}
              scrollToFirstError
            >
              <Form.Item
                name="currentPassword"
                label="Current Password"
                rules={[
                  {
                    min: 4,
                    message: 'Required at least 4 characters long.',
                  },
                  {
                    required: true,
                    message: 'Please input your current password',
                  },
                ]}
              >
                <Input.Password placeholder="Please enter your current password" />
              </Form.Item>
              <Divider />
              <Form.Item
                name="newPassword"
                label="New Password"
                dependencies={['currentPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    min: 4,
                    message: 'Required at least 4 characters long.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('currentPassword') !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('New password must be different from current password'));
                    }
                  })
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Please enter new password" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm New Password"
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your new password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    }
                  })
                ]}
              >
                <Input.Password placeholder="Please confirm new password again" />
              </Form.Item>

              <Form.Item style={{ marginTop: '40px' }}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Change Password
                </Button>
                {/* <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  Clear
                </Button> */}
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ChangePasswordForm