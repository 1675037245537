import { Table } from 'antd'
import { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

interface IStatisticAccessTransaction {
  loading: boolean
  data: IAccessTransaction[]
}

const StatisticAccessTransacion: React.FunctionComponent<IStatisticAccessTransaction> = ({ loading, data }) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      width: 30,
    },
    {
      title: 'Date - Time',
      dataIndex: 'dateTime',
      width: 120
    },
    {
      title: 'Browser',
      dataIndex: 'browser',
      width: 70,
    },
    {
      title: 'Device',
      dataIndex: 'accessFrom',
      width: 200,
    }
  ]

  return (
    <>
      <Table loading={loading} dataSource={data} columns={columns} pagination={{ pageSize: 100, showSizeChanger: false }} size="small" scroll={{ x: '100vw', y: 300 }} />
    </>
  )
}

export default StatisticAccessTransacion
