import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'

const Error404: React.FunctionComponent = () => {
  return (
    <Result
      status="warning"
      title="404, Not Found"
      subTitle="Sorry, page does not exists."
      extra={
        <Link to="/">
          <Button type="primary" key="console">
            Go Home
          </Button>
        </Link>
      }
    />
  )
}

export default  Error404