import { Form, Input, Button } from 'antd'
import { alertMessage } from '../../utils/alert'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

interface ILoginForm {
  submitLoginCallback: any
  isLoading: boolean
}

const LoginForm: React.FunctionComponent<ILoginForm> = ({ submitLoginCallback, isLoading }) => {
  const onFinish = (values: ILoginCredential) => {
    submitLoginCallback(values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
    alertMessage('Please type your username and password.', 'warning', 1)
  }

  return (
    <Form
      name="basic"
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username',
          }
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password',
          }
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="full-width" loading={isLoading}>
          Check credential
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm