import { Divider, Input, Typography, Button, Space, Skeleton } from 'antd'
import { CopyOutlined, CheckOutlined, ApiOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import ReactQr from 'react-awesome-qr'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './sharingForm.less'
import { alertMessage } from '../../utils/alert'
import { useQuery } from '@apollo/client'
import { GRAPH_QUERY_SELF_PROFILE_PUBLIC_URL } from '../../graphql/profile'
import ErrorLoadData from '../errors/loadDataError'

const { Paragraph, Text, Link } = Typography

interface IShareingFormQR {
  text: string
  bgImg: string
  logoImg: string
}

const SharingForm: React.FunctionComponent = () => {
  const [copying, setCopying] = useState<boolean>(false)
  const [qrData, setQrData] = useState<IShareingFormQR>()

  let { loading, error, data } = useQuery(GRAPH_QUERY_SELF_PROFILE_PUBLIC_URL)

  useEffect(() => {
    if (!loading) {
      if (!error) {
        const profileUrl = data?.selfProfile.profileUrl
        const obj: IShareingFormQR = {
          text: profileUrl, // 'https://people.digix.cards/profile/digi/30984651056',
          bgImg: '',
          logoImg: '/static/media/logo.4a7b485f.png'
        }
        setQrData(obj)
      }
    }
  }, [data])

  const onCopy = () => {
    setCopying(true)
    alertMessage('URL has copied!', 'success')
    setTimeout(() => {
      setCopying(false) 
    }, 3000)
  }

  const qrCodeElem = (
    (!loading && qrData?.text) ? 
    <>
      <div id="qrCodeBox">
        <ReactQr
          size={500}
          dotScale={1}
          text={qrData?.text}
          bgSrc={qrData?.bgImg}
          logoSrc={qrData?.logoImg}
          margin={0}
          style={{ width: '100%' }}
          />
      </div>
      <Divider />
      <div className="text-center" style={{ marginTop: '25px' }}>
        <div style={{ marginBottom: '20px' }}>
          <Text type="secondary">Share your profile to anyone!</Text>
        </div>
        {/* <Paragraph copyable={{ tooltips: false }}><span>{qrData?.text}</span></Paragraph> */}
        <Input value={qrData?.text} addonAfter={!copying ? <CopyToClipboard onCopy={onCopy} text={qrData?.text || ''}><a><CopyOutlined /></a></CopyToClipboard> : <Text type="success" strong><CheckOutlined /></Text>} />
        <Link href={qrData?.text} target="_blank"><Button type="link" block style={{ borderTop: 'none' }}>Open this link <ApiOutlined /></Button></Link><br/>
      </div>
    </>
    : <div style={{ height: 300 }}></div>
  )

  const loadingElem = (
    <>
      <Space className="spaceCenter" style={{ width: '100%', margin: 'auto' }}>
        <Skeleton.Input className="width100" style={{ width: '100%', height: 350, margin: 'auto' }} active size="large" />
      </Space>
        <Skeleton />
    </>
  )

  if (error) return <ErrorLoadData />
  return (
    <div style={{ width: '100%', minWidth: '275px', maxWidth: '400px', margin: 'auto' }}>
      <Card title="" bordered={false} className="menu-card active">
        {(loading) ? loadingElem : qrCodeElem}
      </Card>
    </div>
  )
}

export default SharingForm